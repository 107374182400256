import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  daysArray=[{today:'۱۴۰۰/۴/۲۵'}]
  todayDone: boolean=false;

  ngOnInit(){
    this.daysArray=JSON.parse(localStorage.getItem('daysArray'))

  }

  todayCanTrue(){
    this.daysArray.push({today:  new Date().toLocaleDateString('fa-IR')})
    localStorage.setItem('daysArray',JSON.stringify(this.daysArray))
  }
}
